import { createGlobalStyle } from 'styled-components';
import * as colors from './colorSchema';

const fontFamily = "usual, helvetica, sans-serif";

const GlobalStyle = createGlobalStyle`
    html {
        font-size: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
        overflow: auto;
        font-family: ${fontFamily};
        background-color: ${colors.darkestDark};
    }

    body {
        margin: 0;
        padding: 0;
        word-wrap: break-word;
        font-kerning: normal;
        font-weight: 400;
        line-height: 1.75;
        color: ${colors.white};
    }

    p { margin-bottom: 1rem; }

    h1, h2, h3, h4, h5 {
        margin: 3rem 0 1.38rem;
        font-weight: 400;
        line-height: 1.3;
    }

    h1 {
        margin-top: 0;
        font-size: 4.209rem;
    }

    h2 { font-size: 3.157rem; }

    h3 { font-size: 2.369rem; }

    h4 { font-size: 1.777rem; }

    h5 { font-size: 1.333rem; }

    small, .text_small { font-size: 0.75rem; }

    a {
        color: ${colors.red};
        text-decoration: none;

        &:hover {
            padding-bottom: .2rem;
            border-bottom: 1px solid ${colors.red};
        }
    }

    

    li {
        list-style: none;
    }
`

export default GlobalStyle;
