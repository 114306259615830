import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalStyle from "../styles/globalStyle";

const IndexPage = () => (
  <>
    <GlobalStyle />
    <Layout>
      <SEO title="samuelfialho.pt" />
    </Layout>
  </>
)

export default IndexPage
